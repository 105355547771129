exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-holiday-homes-for-rent-index-js": () => import("./../../../src/pages/holiday-homes/for-rent/index.js" /* webpackChunkName: "component---src-pages-holiday-homes-for-rent-index-js" */),
  "component---src-pages-holiday-homes-map-for-rent-index-js": () => import("./../../../src/pages/holiday-homes-map/for-rent/index.js" /* webpackChunkName: "component---src-pages-holiday-homes-map-for-rent-index-js" */),
  "component---src-pages-holiday-homes-map-for-sale-index-js": () => import("./../../../src/pages/holiday-homes-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-holiday-homes-map-for-sale-index-js" */),
  "component---src-pages-off-plan-properties-for-sale-index-js": () => import("./../../../src/pages/off-plan-properties/for-sale/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-for-sale-index-js" */),
  "component---src-pages-off-plan-properties-map-for-sale-index-js": () => import("./../../../src/pages/off-plan-properties-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-map-for-sale-index-js" */),
  "component---src-pages-openstreet-js": () => import("./../../../src/pages/openstreet.js" /* webpackChunkName: "component---src-pages-openstreet-js" */),
  "component---src-pages-properties-commercial-for-rent-index-js": () => import("./../../../src/pages/properties/commercial/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-commercial-for-rent-index-js" */),
  "component---src-pages-properties-commercial-for-sale-index-js": () => import("./../../../src/pages/properties/commercial/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-commercial-for-sale-index-js" */),
  "component---src-pages-properties-for-rent-index-js": () => import("./../../../src/pages/properties/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-for-rent-index-js" */),
  "component---src-pages-properties-for-sale-index-js": () => import("./../../../src/pages/properties/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-for-sale-index-js" */),
  "component---src-pages-properties-map-for-rent-index-js": () => import("./../../../src/pages/properties-map/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-map-for-rent-index-js" */),
  "component---src-pages-properties-map-for-sale-index-js": () => import("./../../../src/pages/properties-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-map-for-sale-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-areaguide-details-template-js": () => import("./../../../src/templates/areaguide-details-template.js" /* webpackChunkName: "component---src-templates-areaguide-details-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-holiday-details-template-js": () => import("./../../../src/templates/holiday-details-template.js" /* webpackChunkName: "component---src-templates-holiday-details-template-js" */),
  "component---src-templates-news-details-template-js": () => import("./../../../src/templates/news-details-template.js" /* webpackChunkName: "component---src-templates-news-details-template-js" */),
  "component---src-templates-offplan-details-template-js": () => import("./../../../src/templates/offplan-details-template.js" /* webpackChunkName: "component---src-templates-offplan-details-template-js" */),
  "component---src-templates-property-details-template-csr-js": () => import("./../../../src/templates/property-details-template-csr.js" /* webpackChunkName: "component---src-templates-property-details-template-csr-js" */),
  "component---src-templates-property-details-template-js": () => import("./../../../src/templates/property-details-template.js" /* webpackChunkName: "component---src-templates-property-details-template-js" */),
  "component---src-templates-team-details-template-js": () => import("./../../../src/templates/team-details-template.js" /* webpackChunkName: "component---src-templates-team-details-template-js" */)
}

